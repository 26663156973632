html {
  scroll-behavior: smooth;
}
.user-left-sidebar {
  .nav-link {
    &.active {
      color: $primary;
      font-weight: bold;
      i {
        color: $primary !important;
      }
    }
  }
}
.profile-information p {
  margin-bottom: 10px;
  font-size: 32px;
}
.h-auto {
  height: auto !important;
}
.fan-list li {
  padding: 8px;
  flex: 0 0 10%;
  text-align: center;
  list-style-type: none;
}
.picker-container {
  position: relative;
}

.emoji-icon {
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 10px;
  width: 24px;
}
.emoji-container {
  position: absolute;
  bottom: 0px;
  right: 62px;
  width: 24px;
}

.input-style {
  border-radius: 3px;
  border: 1px solid #999;
  height: 39px !important;
  overflow-y: hidden;
}
.emoji-picker-react {
  width: 40% !important;
  left: 60%;
  position: absolute !important;
  z-index: 9;
}
.flex-1 {
  flex: 1;
}
.comment-reply-item {
  background-color: #f5f5f5;
  padding: 5px;
}
.button-comment {
  white-space: nowrap;
}
.comment-options span {
  cursor: pointer;
}
.button-load-more-comment button {
  border-radius: 5px;
}
.button-bitcoin-container button {
  border-radius: 5px;
}
.profile-box .pr {
  background: url(https://www.photo-ac.com/assets/profileimages/line.png) top
    left;
  line-height: 30px;
}
.filter-video {
  padding-right: 120px;
  height: 31px;
}
.filter-video-per-page {
  padding-right: 60px;
  height: 31px;
}
.rounded-left-0 {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}
.font-size-21 {
  font-size: 21px;
}
.text-949494 {
  color: #949494 !important;
  transition: 0.3s;
}
.text-949494:hover {
  color: #333 !important;
}
.total-donation:hover {
  text-decoration: underline !important;
  color: #3c9275;
}
.text-area-comment {
  padding: 7px 100px 1px 8px;
}
.link_hover:hover {
  text-decoration: underline !important;
}
.modal-receipt {
  .modal-content {
    width: 160% !important;
    left: -26%;
    margin-top: 60px !important;
  }
  .modal-body {
    padding: 0;
    .close {
      cursor: pointer;
      text-align: initial;
      height: initial;
      background: #fff;
      position: absolute;
      right: -10px;
      top: -10px;
      width: 30px;
      height: 30px;
      padding: 0;
      font-size: 16px;
      text-align: center;
      border-radius: 50%;
      opacity: 1;
      border: 1px solid #eee;
      text-shadow: none;
      z-index: 99;
    }
  }
}
.lh-1 {
  line-height: 1;
}
.bg-ff9700 {
  background-color: #ff9700;
}
.bg-d4b23d {
  background-color: #d4b23d;
}
.bg-055a1e {
  background-color: #055a1e;
}
.bg-d20000 {
  background-color: #d20000;
}
.bg-ffe6ee {
  background-color: #ffe6ee;
}
.text-602f13 {
  color: #602f13;
}
.text-e02d7c {
  color: #e02d7c;
}
.text-e1b928 {
  color: #e1b928;
}
.text-ff9700 {
  color: #ff9700;
}
.text-d4b23d {
  color: #d4b23d;
}
.text-e4c305 {
  color: #e4c305;
}
.text-e02d7c {
  color: #e02d7c;
}
.text-00a600 {
  color: #00a600;
}
.text-602f13 {
  color: #602f13
}
.text-dd477d {
  color: #dd477d;
}
.text-ffef00 {
  color: #ffef00
}
.text-00b4dc {
  color: #00b4dc
}
.text-45a0e5 {
  color: #45a0e5;
}
.text-333333 {
  color: #333333;
}
.text-f18d00 {
  color: #f18d00;
}
.text-f5ff53 {
  color: #f5ff53;
}
.text-7e4928 {
  color: #7e4928;
}
.text-b32128 {
  color: #b32128;
}
.text-76830c {
  color: #76830c;
}
.text-ff8f00 {
  color: #ff8f00;
}
.text-333333 {
  color: #333333;
}
.text-fff014 {
  color: #fff014;
}
.bg-6f20a2 {
  background-color: #6f20a2;
}
.bg-000000 {
  background-color: #000000;
}
.bg-d11525 {
  background-color: #d11525;
}
.bg-ffffff {
  background-color: #ffffff;
}
.bg-ffe6ee {
  background-color: #ffe6ee;
}
.bg-ffee00 {
  background-color: #ffee00;
}
.bg-9ce1f9 {
  background-color: #9ce1f9;
}
.bg-ffef00 {
  background-color: #ffef00;
}
.bg-e6002D {
  background-color: #e6002D;
}
.bg-faff63 {
  background-color: #faff63;
}
.bg-00b4dc {
  background-color: #00b4dc;
}
.bg-e1f5ff {
  background-color: #e1f5ff;
}
.bg-45a0e5 {
  background-color: #45a0e5;
}
.bg-3e5aa8 {
  background-color: #3e5aa8;
}
.bg-292857 {
  background-color: #292857;
}
.bg-fae6aa {
  background-color: #fae6aa;
}
.bg-76830c {
  background-color: #76830c;
}
.bg-7917a8 {
  background-color: #7917a8;
}
.bg-ff8f00 {
  background-color: #ff8f00;
}
.bg-eb140f {
  background-color: #eb140f;
}
.halloween-badge-container {
  background-color: #6f20a2;
}
.font-size-48 {
  font-size: 48px;
}
.max-width-1200 {
  max-width: 1200px;
}
.max-width-1400 {
  max-width: 1400px;
}

.image-right {
  opacity: 95%;
}

.image-right:hover {
  opacity: 85%;
}
.font-size-18 {
  font-size: 18px !important;
}
.text-000000 {
  color: #000000;
}
.font-size-24 {
  font-size: 24px;
}
.btn-creator-work:hover {
  border: solid 1px #3c9275 !important;
  background-color: #3c9275 !important;
  color: #fff !important;
}
@media only screen and (max-width: 640px) {
  .google-ads {
    display: none !important;
  }
}